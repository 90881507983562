<template>
    <div class="vista-escritorio">
        <Cabecera/>
        <Cuerpo>
            <div class="votos ">
                <div class="votos-cabecera">
                    <div class="titulos">
                      <h2>
                        {{ eleccion }} -
                        <span>
                          <strong>Entidad</strong>
                          - Votos por Partido Político, Candidatura Independiente y Candidatura Común
                        </span>
                      </h2>
                      <h4><strong>Baja California Sur</strong></h4>
                      <p>
                        El total de votos mostrado a nivel Entidad representa la suma del
                        voto emitido en territorio Estatal y en el Extranjero. <br/>
                        Por presentación, los decimales de los porcentajes muestran sólo cuatro
                        dígitos. No obstante, al considerar todos los decimales, suman 100%.
                      </p>
                      <h3>Votos en Actas Contabilizadas</h3>
                    </div>
                    <div class="visualizar-como">
                        <h3>Visualiza como:</h3>
                        <div>
                            <button
                                :class="{ 'visualizar-activo': mostrarGrafica }"
                                @click="manejarMostrarGrafica(true)"
                            >
                                <span>
                                    <img src='@/assets/iconos-visualiza/Grafica-B.svg' alt="">
                                    <span>Gráfica</span>
                                </span>

                            </button>
                            <button
                                :class="{ 'visualizar-activo': !mostrarGrafica }"
                                @click="manejarMostrarGrafica(false)"
                            >
                                <span>
                                    <img src='@/assets/iconos-visualiza/Tarjetas-A.svg' alt="">
                                    <span>Tarjetas</span>
                                </span>
                            </button>
                        </div>

                    </div>
                </div>
                <div class="tarjetas votos-por-partido" v-if="!mostrarGrafica">
                    <ul>
                        <li v-for="(partido, index) in votos.partidos" :key="index">
                            <div>
                                <div class="partidos">
                                    <ul>
                                        <li>
                                            <div>
                                                <img :src="partido.partido_logo" alt="">
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="total-votos">
                                    <div>
                                        <h3>Total de votos</h3>
                                        <div>
                                            <span> <strong>{{ partido.partido_total_votos_general_numero }}</strong> </span>
                                            <span>{{partido.partido_total_votos_general_porcentaje}}%</span>
                                        </div>
                                    </div>
                                    <div class="votos-disponibles" v-if="eleccion === 'Gubernatura'">
                                        <h3>Votos</h3>
                                        <h4>En la Entidad</h4>
                                        <div>
                                            <span> <strong>{{ partido.partido_total_votos_entidad_numero }}</strong> </span>
                                            <span>{{ partido.partido_total_votos_entidad_porcentaje }}%</span>
                                        </div>
                                        <h4>En el extranjero</h4>
                                        <div>
                                            <span> <strong>{{ partido.partido_total_votos_extranjero_numero }}</strong> </span>
                                            <span>{{ partido.partido_total_votos_extranjero_porcentaje }}%</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <b-form-checkbox class="boton-seleccionar"
                                    button
                                    v-model="partido.seleccionado"
                                    :class="{'seleccionado': partido.seleccionado }"
                                >
                                    <span v-if="!partido.seleccionado">
                                        Seleccionar
                                    </span>
                                    <span v-else>
                                        Quitar selección
                                    </span>
                                </b-form-checkbox> -->
                                <b-form-checkbox
                                    class="boton-seleccionar seleccionado"
                                    button
                                    v-model="partido.seleccionado"
                                    v-if="partido.seleccionado"
                                >
                                    Quitar selección
                                </b-form-checkbox>
                                <b-form-checkbox
                                    class="boton-seleccionar"
                                    button
                                    v-model="partido.seleccionado"
                                    v-else
                                    :disabled="tresSeleccionados"
                                >
                                    Seleccionar
                                </b-form-checkbox>
                            </div>
                        </li>
                    </ul>
                    <div class="text-left mb-3" v-if="eleccion === 'Diputaciones'">
                      <!-- <p class="mb-1">
                        <b>
                          La cantidad de votos de la Diputación Migrante no
                          se contabiliza en el total de votos a nivel Entidad.
                        </b>
                      </p> -->
                      <p class="mb-1">
                        <b>
                          En esta tabla se considera la cantidad de votos asentada
                          en las actas PREP de Casilla Especial de Representación
                          Proporcional.
                        </b>
                      </p>
                    </div>
                    <div class="info text-right">
                        <p>  <a href="#"><font-awesome-icon icon="info-circle" /></a> Da clic en <strong>Seleccionar</strong>  para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.</p>
                        <button class="boton-azul" v-b-modal.DetalleEleccionPartidosPoliticosModal>
                            Ver detalle
                        </button>
                    </div>
                </div>
                <div class="graficas-votos-por-partido" :class="eleccion" v-else>
                    <div class="tabla">
                        <ul>
                            <li class="titulos">
                                    <div class="numeros-eje-y">
                                        <ul>
                                            <li ref="panelVotos">
                                                <span>{{ obtenerEscala*4 }}</span>
                                            </li>
                                            <li>
                                                <span>{{ obtenerEscala*3 }}</span>
                                            </li>
                                            <li>
                                                <span>{{ obtenerEscala*2 }}</span>
                                            </li>
                                            <li>
                                                <span>{{ obtenerEscala }}</span>
                                            </li>
                                            <li>
                                                <span>0</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="texto">
                                        <ul>
                                            <li>
                                                <h2>Votos</h2>
                                                <h3>Total</h3>
                                            </li>
                                            <li>
                                                <h4>Porcentaje</h4>
                                            </li>
                                            <li class="entidad">
                                                <h4>En la entidad</h4>
                                            </li>
                                            <li class="extranjero">
                                                <h4>En el Extranjero</h4>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            <li v-for="(partido, index) in votosXPartidoGraficas.partidos" :key="index" >
                                <div>
                                    <div class="barra" :style="calcularEstiloElemento(partido, index)"></div>
                                </div>

                                <div>
                                    <div class="avatar-nombre">
                                        <div>
                                            <img v-if="partido.partido_logo != 'archivo_logo_partido.png'" :src="partido.partido_logo" alt="" style="width:50px; height:50px">
                                            <div v-else :class="calcularEspacios(partido.partido_nombre) ? 'candidatura-especial' : ''" style="height:50px; text-align:center"></div>
                                        </div>
                                        <div class="nombres">
                                            <span :class="partido.partido_logo == 'archivo_logo_partido.png' ? 'salto-linea' : ''"></span>
                                        </div>
                                    </div>
                                    <div class="total-votos">
                                        <div class="votos-tabla">
                                            <ul>
                                                <li>
                                                    <h3>{{partido.partido_total_votos_general_numero}}</h3>
                                                </li>
                                                <li>
                                                    <h4>{{partido.partido_total_votos_general_porcentaje}}%</h4>
                                                </li>
                                                <li class="entidad">
                                                    <h4>{{partido.partido_total_votos_entidad_numero}}</h4>
                                                </li>
                                                <li class="extranjero">
                                                    <h4>{{partido.partido_total_votos_extranjero_numero}}</h4>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="row" v-if="eleccion === 'Diputaciones'">
                      <div class="col-12">
                        <p>
                          <b>
                            En esta tabla se considera la cantidad de votos asentada en las
                            Actas PREP de la Casilla Especial de la Representación Proporcional.
                          </b>
                        </p>
                      </div>
                    </div>
                </div>
                <div class="resumen">
                    <div class="resumen-votacion" v-if="eleccion == 'Gubernatura'">
                        <h3>Resumen de la votación</h3>
                        <div>
                            <div class="cuadro-principal">
                                <div>
                                    <h4>En la Entidad</h4>
                                    <h5>{{gubernaturaResumenNivelEntidad.total_votos_por_entidad_numero}}</h5>
                                    <h6> {{gubernaturaResumenNivelEntidad.total_votos_por_entidad_porcentaje}}%</h6>
                                </div>
                                <div class="icono-mas">
                                    +
                                </div>
                                <div>
                                    <h4>En el extranjero</h4>
                                    <h5>{{gubernaturaResumenNivelEntidad.total_votos_extranjero_numero}}</h5>
                                    <h6>{{gubernaturaResumenNivelEntidad.total_votos_extranjero_porcentaje}}%</h6>
                                </div>
                            </div>
                            <div class="icono-mas">
                                   +
                            </div>
                            <div>
                                <h4>Candidaturas no registradas</h4>
                                <h5>{{gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_numero}}</h5>
                                <h6>{{gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_porcentaje}}%</h6>
                            </div>
                            <div class="icono-mas">
                                   +
                            </div>
                            <div>
                                <h4>Nulos</h4>
                                <h5>{{gubernaturaResumenNivelEntidad.total_votos_nulos_numero}}</h5>
                                <h6>{{gubernaturaResumenNivelEntidad.total_votos_nulos_porcentaje}}%</h6>
                            </div>
                            <div class="icono-igual">
                                    =
                            </div>
                            <div class="total-de-votos">
                                <h4>Total de votos</h4>
                                <h5>{{gubernaturaResumenNivelEntidad.total_votos_general_numero}}</h5>
                                <h6>{{gubernaturaResumenNivelEntidad.total_votos_general_porcentaje}}%</h6>
                            </div>
                        </div>
                    </div>

                    <div class="resumen-votacion" v-else>
                      <h4 class="mb-2">Resumen de la votación</h4>
                      <div class="grilla-suma">
                        <div class="acumulados text-center">
                          <p class="font-weight-bold">
                            Votos acumulados
                            <span id="tooltip">
                              <font-awesome-icon
                                icon="info-circle"
                                class="fa-sm fa-info"
                              />
                              <span class="tooltiptext">
                                Es la sumatoria de los votos obtenidos <br />
                                por Partido Político, Coalición <br />
                                o Candidatura Independiente.
                              </span>
                            </span>
                            <br />
                            {{ gubernaturaResumenNivelEntidad.total_votos_por_entidad_numero }} <br />
                            <span class="porcentaje">
                              {{ gubernaturaResumenNivelEntidad.total_votos_por_entidad_porcentaje }}%
                            </span>
                          </p>
                        </div>
                        <div class="icono-suma">
                          <font-awesome-icon icon="plus" class="fa-2x" />
                        </div>
                        <div class="no-registradas text-center">
                          <p class="font-weight-bold">
                            Candidaturas no registradas <br />
                            {{ gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_numero }}
                            <br />
                            <span class="porcentaje">
                              {{
                                gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_porcentaje
                              }}%
                            </span>
                          </p>
                        </div>
                        <div class="icono-suma">
                          <font-awesome-icon icon="plus" class="fa-2x" />
                        </div>
                        <div class="nulos text-center">
                          <p class="font-weight-bold">
                            Nulos <br />
                            {{ gubernaturaResumenNivelEntidad.total_votos_nulos_numero }} <br />
                            <span class="porcentaje">
                              {{ gubernaturaResumenNivelEntidad.total_votos_nulos_porcentaje }}%
                            </span>
                          </p>
                        </div>
                        <div class="icono-suma">
                          <font-awesome-icon icon="equals" class="fa-2x" />
                        </div>
                        <div class="total-de-votos">
                          <h4>Total de votos</h4>
                          <h5>
                            {{
                              gubernaturaResumenNivelEntidad.total_votos_general_numero
                            }}
                          </h5>
                          <h6>
                            {{
                              gubernaturaResumenNivelEntidad.total_votos_general_porcentaje
                            }}%
                          </h6>
                        </div>
                      </div>
                    </div>


                </div>
            </div>
            <div class="votos-extranjero" v-if="eleccion === 'Gubernatura'">
                <div>
                    <font-awesome-icon icon="globe-americas" />
                    <h2>Voto en el extranjero</h2>
                    <router-link to="/G/VE/" class="boton-azul">Ver Actas</router-link>
                </div>
            </div>

            <div class="votos-representacion" v-else-if="eleccion === 'Diputaciones'">
                <AccesoRepresentacionProporcional />
            </div>

            <estadistica-entidad/>
            <div class="text-center">
                <p>
                    Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto, no tienen efectos jurídicos. <br /> Con base en la Ley Federal del Derecho de Autor queda prohíbida cualquier modificación al diseño de este sitio. <br />
                    La modificación y/o destrucción de la información y/o contenido total o parcial de este sitio, es delito federal de acuerdo al Código Penal Federal.
                </p>
            </div>
        </Cuerpo>
        <Compartir/>
        <PieDePagina />

        <detalle-eleccion-partidos-politicos
            v-if="anchoPantalla >= 992"
            :resumen="gubernaturaResumenNivelEntidad"
            :votos="votos"
        />

    </div>
</template>
<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo';
import Compartir from '@/components/Compartir.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue';
import AccesoRepresentacionProporcional from '@/components/Movil/componentes/AccesoRepresentacionProporcional.vue';
import DetalleEleccionPartidosPoliticos from '@/components/DetalleEleccionPartidosPoliticos.vue';
import numeral from 'numeral';

export default {
  name: 'VotosPorPartidoPoliticoEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    EstadisticaEntidad,
    PieDePagina,
    AccesoRepresentacionProporcional,
    DetalleEleccionPartidosPoliticos,
  },
  computed: {
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }

      return '';
    },
    detalle() {
      return this.$store.state.entidad.votosPartidosPoliticos;
    },
    gubernaturaResumenNivelEntidad() {
      const categorias = {
        G: this.$store.state.gResumenNivelEntidad,
        A: this.$store.state.aResumenNivelEntidad,
        D: this.$store.state.dResumenNivelEntidadConRP,
      };
      const { eleccion } = this.$route.params;

      return categorias[eleccion];
    },
    votosXPartidoTarjetaEscritorio() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXPartidoTarjeta;
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXPartidoTarjeta;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXPartidoTarjeta;
      }

      return {};
    },
    votosXPartidoGraficas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXPartidoGraficas;
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXPartidoGraficas;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXPartidoGraficas;
      }

      return {};
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
    tresSeleccionados() {
      let seleccionados = 0;

      this.votos.partidos.map((partido) => {
        if (partido.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }
        return null;
      });

      return seleccionados === 3;
    },
    obtenerMaximo() {
      const max = Math.max.apply(Math, this.votosXPartidoGraficas.partidos.map((p) => numeral(p.partido_total_votos_general_numero).value()));

      return max;
    },
    obtenerEscala() {
      return Math.trunc((this.obtenerMaximo * 1.2) / 4);
    },
  },
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [
        ['', 'Votos', { role: 'style' }],
        ['', 21638, '#2659A5'],
        ['', 55059, '#4F944B'],
        ['', 4525, '#E3C650'],
        ['', 178107, '#D63F33'],
        ['', 123572, '#E98932'],
        ['', 6579, '#00A6B4'],
        ['', 2262, '#A83A28'],
        ['', 54817, '#5D2C7F'],
      ],
      chartOptions: {
        height: 200,
        width: 1550,
        bar: { groupWidth: '54' },
        legend: { position: 'none' },
        colors: ['#DD3B5D', '#00B6FF', '#9E9E9E'],
        vAxis: { gridlines: { count: 4 }, textStyle: { color: '#BABABA' } },
        // colors: ['#2659A5', '#4F944B', '#E3C650', '#D63F33', '#E98932','#00A6B4','#A83A28','#5D2C7F']
      },
      mostrarGrafica: true,
      altoVotos: 10,
      votos: {},
      random: 0,
    };
  },
  methods: {
    manejarMostrarGrafica(value) {
      //   console.log(value)
      this.mostrarGrafica = value;
    },
    calcularAltoSobrante(vPartido) {
      const factor = this.altoVotos * 5 + 15;
      const valH = (factor * vPartido.partido_total_votos_general_porcentaje / 100);
      return `padding-top:${String(factor - valH)}px;`;
    },
    calcularEstiloElemento(vPartido) {
      const votos = numeral(vPartido.partido_total_votos_general_numero).value();
      const valH = votos * 140 / (this.obtenerMaximo * 1.2);
      return `${'width: 56px;' + 'height:'}${String(valH)}px;` + `background-color:${vPartido.partido_color};`;
    },
    onCheckboxChange(e) {
      this.$forceUpdate();
      this.random = Math.random();
    },
    calcularEspacios(partido) {
      return partido.indexOf(' ') >= 0;
    },
    calcularAltoSobrante(vPartido) {
      const factor = this.altoVotos * 5 + 15;
      const valH = (factor * vPartido.partido_total_votos_general_porcentaje / 100);
      return `padding-top:${String(factor - valH)}px;`;
    },
    onCheckboxChange(e) {
      this.$forceUpdate();
      this.random = Math.random();
    },
    calcularEspacios(partido) {
      return partido.indexOf(' ') >= 0;
    },
  },
  mounted() {
    this.altoVotos = this.$refs.panelVotos.clientHeight;
  },
  created() {
    if (this.$route.params.eleccion === 'G') {
      this.votos = this.$store.state.gVotosXPartidoTarjeta;
    } else if (this.$route.params.eleccion === 'D') {
      this.votos = this.$store.state.dVotosXPartidoTarjeta;
    } else if (this.$route.params.eleccion === 'A') {
      this.votos = this.$store.state.aVotosXPartidoTarjeta;
    }
  },
};
</script>

<style lang="scss" scoped>
.votos-extranjero {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../assets/Fondo_Mapa.png");
}

.votos-representacion {
  text-align: center;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  margin-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
