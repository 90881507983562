<template>
  <section class="diputacion-migrante">
    <p class="primario text-center font-weight-bold">
      <big>
        Diputación Migrante
      </big>
    </p>

    <div class="grilla">
      <div class="total-votos">
        <p>
          <b>
            Total de votos
          </b> <br />
          <span class="primario">
            2,569
          </span>
        </p>
      </div>
      <div class="porcentaje">
        <p class="font-weight-bold text-right">
          7.4451%
        </p>
      </div>
    </div>

    <p class="text-center">
      Partido Político o Coalición que ha obtenido la Diputación Migrante hasta el momento.
    </p>

    <button class="btn btn-primary d-block mx-auto text-center mb-3">
      Ver Actas
    </button>
  </section>
</template>

<script>
export default {
  name: 'DiputacionMigrante'
}
</script>

<style lang="scss" scoped>
.diputacion-migrante {
  background-color: #f5f5f5;
  border: 2px solid #d2127e;
  padding: 10px;
  margin-bottom: 30px;
  background-image: url('../../../assets/Fondo_Mapa.png');
  background-size: cover;
  background-repeat: no-repeat;

  .grilla {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: flex-end;
    padding: 20px;
  }

  button {
    background-color: #23233e;
    color: #f2f2f2;
    border-color: transparent;
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>