<template>
  <section class="acceso-representacion-proporcional">
    <p class="text-center font-weight-bold">
      <big>
        Representación Proporcional
      </big>
    </p>

    <p>
      El apartado "Actas RP" muestra la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional, los cuales se suman únicamente a nivel Entidad.
    </p>

    <router-link to="/representacion-proporcional" class="btn btn-primary d-block mx-auto text-center mb-3">
      Ver Actas
    </router-link>
  </section>
</template>

<script>
  export default {
    name: 'AccesoRepresentacionProporcional'
  }
</script>

<style lang="scss" scoped>
.acceso-representacion-proporcional {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 45px;
  padding: 30px;

  .btn {
    background-color: #23233e;
    color: #f2f2f2;
    border-color: transparent;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 992px) {
    max-width: 600px;
    display: block;
    margin: 0 auto;

    .btn {
      max-width: 150px;
    }
  }
}
</style>