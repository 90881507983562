<template>
  <layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <h4>
          {{ titulo }} - <b class="primario">Entidad</b> -
          <span class="primario"
            >Votos por Partido Político, Candidatura Independiente y Candidatura Común</span
          >
        </h4>

        <p>
          <b> Baja California Sur </b>
        </p>

        <p>
          El total de votos mostrado a nivel Entidad representa la suma del voto
          emitido en territorio Estatal y en el Extranjero.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo
          cuatro digitos. No obstante, al considera todos los decimales, suman
          100%.
        </p>

        <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumen.total_votos_general_porcentaje }}%
          </p>

          <p class="primario mb-0 font-weight-bold">Total de votos en:</p>
          <div class="grilla-votos">
            <div class="votos">
              <p class="mb-2">
                <b> La Entidad </b>
              </p>

              <h4 class="text-right">
                {{ resumen.total_votos_por_entidad_numero }}
              </h4>
            </div>
            <div class="votos">
              <p class="mb-2">
                <b> El Extranjero </b>
              </p>

              <h4 class="text-right">
                {{ resumen.total_votos_extranjero_numero }}
              </h4>
            </div>
          </div>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">Votos en Actas Contabilizadas</p>

          <p>
            Presiona sobre el recuadro blanco y selecciona de una a tres
            opciones para conocer el detalle de la votación.
          </p>
        </div>

        <div class="row">
          <voto-por-partido
            v-for="(partido, index) in votos.partidos"
            :key="index"
            :partido="partido"
            :esDetalle="false"
          />
        </div>

        <div class="resumenDeLaVotacion mb-5">
          <p class="titulo">Resumen de la votación</p>

          <ResumenVotacion
            texto="En la Entidad"
            :numero="resumen.total_votos_por_entidad_numero"
            :porcentaje="resumen.total_votos_por_entidad_porcentaje"
            v-if="eleccion === 'G'"
          />
          <ResumenVotacion
            texto="En el Extranjero"
            :numero="resumen.total_votos_extranjero_numero"
            :porcentaje="resumen.total_votos_extranjero_porcentaje"
            v-if="eleccion === 'G'"
          />
          <ResumenVotacion
            texto="Candidaturas no registradas"
            :numero="resumen.total_votos_candidatura_noregistrada_numero"
            :porcentaje="
              resumen.total_votos_candidatura_noregistrada_porcentaje
            "
          />
          <ResumenVotacion
            texto="Nulos"
            :numero="resumen.total_votos_nulos_numero"
            :porcentaje="resumen.total_votos_nulos_porcentaje"
          />

          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-primary float-right"
                v-b-modal.DetalleEleccionPartidosPoliticosModal
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>

        <div
          class="votoEnElExtranjero px-4 py-2 text-center mb-5"
          v-if="eleccion === 'G'"
        >
          <font-awesome-icon icon="globe-americas" class="fa-3x mb-3" />
          <h4 class="font-weight-bold mb-3">Voto en el Extranjero</h4>

          <router-link
            to="/G/VE"
            class="btn btn-primary mx-auto d-block btn-actas px-4 w-50"
          >
            Ver Actas
          </router-link>
        </div>

        <div v-if="eleccion === 'D'">
          <!-- <DiputacionMigrante /> -->

          <AccesoRepresentacionProporcional />
        </div>

        <EstadisticaEntidad />
      </div>

      <detalle-eleccion
        v-if="anchoPantalla < 992"
        :resumen="gubernaturaResumenNivelEntidad"
        :votos="votos"
      />
    </div>
  </layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import VotoPorPartido from '@/components/Movil/componentes/VotoPorPartido.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion.vue';
import GraficaListaNominal from '@/components/Movil/componentes/GraficaListaNominal';
import DetalleEleccion from '@/components/DetalleEleccionPartidosPoliticos.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import DiputacionMigrante from '@/components/Movil/componentes/DiputacionMigrante.vue';
import AccesoRepresentacionProporcional from '@/components/Movil/componentes/AccesoRepresentacionProporcional.vue';

export default {
  name: 'VotosPorPartidosPoliticosMovil',
  components: {
    Layout,
    VotoPorPartido,
    ResumenVotacion,
    GraficaListaNominal,
    DetalleEleccion,
    EstadisticaEntidad,
    DiputacionMigrante,
    AccesoRepresentacionProporcional,
  },
  data() {
    return {
      random: 0,
      votos: {},
    };
  },
  computed: {
    titulo() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamiento';
      }
      return 'Diputaciones';
    },
    resumen() {
      if (this.$route.params.eleccion === 'G') {
        // return this.$store.getters.obtenerResumenVotosGubernatura
        return this.$store.state.gResumenNivelEntidad;
      }
      return this.$store.state.dResumenNivelEntidad;
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'f1b1d7';
      }
      return 'd2127e';
    },
    votosXPartidoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXPartidoTarjeta;
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXPartidoTarjeta;
      }
      return this.$store.state.aVotosXPartidoTarjeta;
    },
    gubernaturaResumenNivelEntidad() {
      if (this.$route.params.eleccion == 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion == 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }
      return this.$store.state.gResumenNivelEntidad;
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
  methods: {
    setearVotos() {
      if (this.cargaTerminada) {
        if (this.eleccion === 'G') {
          this.votos = this.$store.state?.gVotosXPartidoTarjeta;
        } else if (this.eleccion === 'D') {
          this.votos = this.$store.state?.dVotosXPartidoTarjeta;
        } else {
          this.votos = this.$store.state?.aVotosXPartidoTarjeta;
        }
      }
    },
  },
  created() {
    this.setearVotos();
  },
  updated() {
    this.setearVotos();
  },
};
</script>
